<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return">
					<el-button type="text" size="small" @click="back()">返回</el-button>
				</p>
				<div class="h-b">
					<el-button type="primary" size="small" @click="addCustomerInfo" v-right-code="'Customerinfo:Add'">新增</el-button>
				</div>
				<div class="h-b">
					<el-button type="text" size="small" @click="saveCustomerInfo" v-right-code="'Customerinfo:Add'">保存</el-button>
				</div>
			</div>
		</div>
		<div class="form-list">
			<el-form ref="_customerInfoForm" :model="dataSource" :rules="baseCheckRule">
				<el-tabs class="done" type="border-card" v-model="activeName" @tab-click="tabClick">
					<el-tab-pane label="基本信息" name="tabCustomerInfo">
						<div class="form-list">
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主名称：</el-col>
								<el-col :span="7">
									<el-form-item prop="CustomerName">
										<el-input v-model="dataSource.CustomerName" :maxlength="30" :minlength="4"
											placeholder="货主名称"></el-input>
									</el-form-item>
								</el-col>
								<div :hidden="!isDisabled">
									<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主编码：</el-col>
									<el-col :span="7">
										<el-form-item prop="CustomerCode">
											<el-input v-model="dataSource.CustomerCode" :maxlength="30" :minlength="4"
												:disabled="true" placeholder="货主编码"></el-input>
										</el-form-item>
									</el-col>
								</div>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title">地区：</el-col>
								<el-col :span="9" class="form-title">
									<Distpicker :placeholders="dataSource"></Distpicker>
								</el-col>
								<el-col :span="8" class="form-title">
									<el-form-item prop="CustomerAddress">
										<el-input v-model="dataSource.CustomerAddress" :maxlength="200" placeholder="详细地址">
										</el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title">联系人：</el-col>
								<el-col :span="7">
									<el-form-item prop="CustomerContactName">
										<el-input v-model="dataSource.CustomerContactName" :maxlength="30" :minlength="4"
											placeholder="联系人"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">联系电话：</el-col>
								<el-col :span="7">
									<el-form-item prop="CustomerContactPhone">
										<el-input v-model="dataSource.CustomerContactPhone" :maxlength="30" :minlength="4"
											placeholder="联系电话"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>行业类型：</el-col>
								<el-col :span="7">
									<el-form-item prop="IndustryType">
										<DictionarySelect v-model="dataSource.IndustryType" dicTypeCode="IndustryTypeCode"></DictionarySelect>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">助记码：</el-col>
								<el-col :span="7">
									<el-form-item prop="mnemonic">
										<el-input v-model="dataSource.Mnemonic" placeholder="助记码"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主类型：</el-col>
								<el-col :span="7">
									<el-form-item prop="CustomerType">
										<DictionarySelect v-model="dataSource.CustomerType" dicTypeCode="CustomerTypeCode"></DictionarySelect>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>状态：</el-col>
								<el-col :span="7">
									<el-radio-group v-model="dataSource.CustomerStatus">
										<el-radio :label="1">启用</el-radio>
										<el-radio :label="2">停用</el-radio>
									</el-radio-group>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title">描述：</el-col>
								<el-col :span="17">
									<el-form-item prop="Memo">
										<el-input type="textarea" v-model="dataSource.Memo" :maxlength="500"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
						</div>
					</el-tab-pane>
					<el-tab-pane label="货主门户" name="tabUserInfo" v-if="false">
						<div class="form-list">
							<el-form ref="_accountInfoForm" :model="dataSource" :rules="accountInfoCheckRule">
								<el-form-item>
									<el-col :span="3" class="form-title"><span style="color:red;">*</span>开通货主端：</el-col>
									<el-col :span="7">
										<el-radio-group v-model="dataSource.IsOpenShipper">
											<el-radio :label="true">是</el-radio>
											<el-radio :label="false">否</el-radio>
										</el-radio-group>
									</el-col>
								</el-form-item>
								<el-form-item :hidden="!dataSource.IsOpenShipper">
									<el-col :span="3" class="form-title">
										<span style="color:red;">*</span>管理员账号：
									</el-col>
									<el-col :span="7">
										<el-form-item prop="UserName">
											<el-input v-model="dataSource.UserName" :maxlength="30" :minlength="4"
												:disabled="true" placeholder="管理员账号"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="3" class="form-title">
										<span style="color:red;">*</span>货主域名：
									</el-col>
									<el-col :span="7">
										<el-form-item prop="DomainName">
											<el-input v-model="dataSource.DomainName" :maxlength="30" :minlength="4"
												:disabled="isDisabled&&dataSource.IsExistUser" placeholder="货主域名"></el-input>
										</el-form-item>
									</el-col>
								</el-form-item>
								<el-form-item :hidden="!dataSource.IsOpenShipper">
									<el-col :span="3" class="form-title">
										<span style="color:red;">*</span>登录密码：
									</el-col>
									<el-col :span="7">
										<el-form-item prop="UserPassword">
											<el-input type="password" v-model="dataSource.UserPassword" :maxlength="30"
												:minlength="4" :disabled="isDisabled&&dataSource.IsExistUser"
												placeholder="登录密码"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="3" class="form-title">
										<span style="color:red;">*</span>确认密码：
									</el-col>
									<el-col :span="7">
										<el-form-item prop="UserPasswordConfirm">
											<el-input type="password" v-model="dataSource.UserPasswordConfirm"
												:disabled="isDisabled&&dataSource.IsExistUser" :maxlength="30" :minlength="4"
												placeholder="确认密码"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="1" class="form-title" :hidden="!isDisabled">
										<el-button @click="showReset" style="padding: 0 4px;"
											v-right-code="'Customer_Right_Shipper_ShipperInfo_ResetPass'" size="small"
											type="text">重置密码</el-button>
									</el-col>
								</el-form-item>
							</el-form>
						</div>
					</el-tab-pane>
					<el-tab-pane label="仓库设置" name="tabWarehouse">
						<el-table :data="dataSource.CustomerWarehouseList" ref="customerWarehouseTable" tooltip-effect="dark"
							@selection-change="warehouseSelectionChange" border highlight-current-row>
							<el-table-column type="selection" width="55"></el-table-column>
							<el-table-column prop="WarehouseNo" label="仓库编码">
								<template slot-scope="scope">
									<span>{{scope.row.WarehouseNo}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="WarehouseName" label="仓库名称">
								<template slot-scope="scope">
									<span>{{scope.row.WarehouseName}}</span>
								</template>
							</el-table-column>
							<el-table-column label="入库默认仓库">
								<template slot-scope="scope">
									<el-switch v-model="scope.row.DefaultRKWarehouse"
										@change="defaultRKWarehouse(scope.$index, scope.row)" active-color="#13ce66"
										inactive-color="#ff4949"></el-switch>
								</template>
							</el-table-column>
							<el-table-column label="出库默认仓库">
								<template slot-scope="scope">
									<el-switch v-model="scope.row.DefaultCKWarehouse"
										@change="defaultCKWarehouse(scope.$index, scope.row)" active-color="#13ce66"
										inactive-color="#ff4949"></el-switch>
								</template>
							</el-table-column>
						</el-table>
					</el-tab-pane>
					<el-tab-pane label="承运商设置" name="tabMember">
						<el-table :data="dataSource.CustomerMemberList" ref="customerMemberTable" tooltip-effect="dark"
							@selection-change="memberSelectionChange" border highlight-current-row>
							<el-table-column type="selection" width="55"></el-table-column>
							<el-table-column prop="MemberCode" label="承运商编码">
								<template slot-scope="scope">
									<span>{{scope.row.MemberCode}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="MemberName" label="承运商名称">
								<template slot-scope="scope">
									<span>{{scope.row.MemberName}}</span>
								</template>
							</el-table-column>
							<el-table-column label="承运商产品" v-if="false">
								<template slot-scope="scope">
									<el-link type="primary" :underline="false" :disabled="!isDisabled"
										@click="defaultPayTypeDialog(scope.row)">设置</el-link>
								</template>
							</el-table-column>
							<el-table-column label="启用电子面单" v-if="false">
								<template slot-scope="scope">
									<!-- <el-switch v-model="scope.row.FaceBillStatus" active-color="#13ce66"
										inactive-color="#ff4949">
									</el-switch> -->
									<!-- <el-button v-show="scope.row.FaceBillStatus" @click="faceBillDialog(scope.row)">设置
									</el-button> -->
									<el-link type="primary" :underline="false" :disabled="!isDisabled"
										@click="faceBillDialog(scope.row)">
										设置
									</el-link>
								</template>
							</el-table-column>
							<el-table-column label="入库默认承运商">
								<template slot-scope="scope">
									<el-switch v-model="scope.row.DefaultRKMember"
										@change="defaultRKMember(scope.$index, scope.row)" active-color="#13ce66"
										inactive-color="#ff4949"></el-switch>
								</template>
							</el-table-column>
							<el-table-column label="出库默认承运商">
								<template slot-scope="scope">
									<el-switch v-model="scope.row.DefaultCKMember"
										@change="defaultCKMember(scope.$index, scope.row)" active-color="#13ce66"
										inactive-color="#ff4949"></el-switch>
								</template>
							</el-table-column>
						</el-table>
					</el-tab-pane>
				</el-tabs>
				<el-tabs class="done" type="border-card" v-model="activeName1">
					<el-tab-pane label="联系人" name="tabproductinfo">
						<div class="h-handle-button">
							<div class="h-b">
								<el-button type="primary" size="small" @click="addContactInfo">新增</el-button>
							</div>
						</div>
						<div class="h-table-list">
							<div class="h-scroll">
								<div class="table-s">
									<el-table :data="dataSource.ContactInfoList" border highlight-current-row>
										<el-table-column width="55" label="序号">
											<template slot-scope="scope">
												<span>{{scope.$index+1}}</span>
											</template>
										</el-table-column>
										<el-table-column prop="ContactName" label="联系人">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-input v-model="scope.row.ContactName" size="mini" placeholder="联系人" />
												</el-col>
											</template>
										</el-table-column>
										<el-table-column prop="ContactPhone" label="联系电话">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-input v-model="scope.row.ContactPhone" size="mini" placeholder="联系电话" />
												</el-col>
											</template>
										</el-table-column>
										<el-table-column prop="Email" label="电子邮箱">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-input v-model="scope.row.Email" size="mini" placeholder="电子邮箱" />
												</el-col>
											</template>
										</el-table-column>
										<el-table-column fixed="right" label="操作" width="120">
											<template slot-scope="scope">
												<el-button @click="dataSource.ContactInfoList.splice(scope.$index, 1)" size="small" type="text">删除</el-button>
											</template>
										</el-table-column>
									</el-table>
								</div>
							</div>
						</div>
					</el-tab-pane>

				</el-tabs>
				<el-tabs class="done" type="border-card" v-model="activeName2">
					<el-tab-pane label="装卸货地址" name="tabproductinfo">
						<div class="h-handle-button">
							<div class="h-b">
								<el-button type="primary" size="small" @click="addAddressInfo">新增</el-button>
							</div>
						</div>
						<div class="h-table-list menulist">
							<div class="h-scroll">
								<div class="table-s">
									<el-table :data="dataSource.AddressInfoList" border highlight-current-row>
										<el-table-column width="55" label="序号">
											<template slot-scope="scope">
												<span>{{scope.$index+1}}</span>
											</template>
										</el-table-column>
										<el-table-column prop="Name" label="名称">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-input v-model="scope.row.Name" size="mini" placeholder="名称" />
												</el-col>
											</template>
										</el-table-column>
										<el-table-column prop="addressType" label="地址类型">
											<template slot-scope="scope">
												<el-col :span="23">
													<!-- <el-select v-model="dataSource.AddressType" collapse-tags placeholder="请选择" :maxlength="200" :disabled="isDisabled">
														<el-option v-for="item in scope.row.AddressTypeList" :key="item.Key" :label="item.Value" :value="item.Key">
														</el-option>
													</el-select> -->
													<DictionarySelect v-model="scope.row.AddressType" datType='Int' dicTypeCode="PlatformAddressTypeCode">
													</DictionarySelect>
												</el-col>
											</template>
										</el-table-column>
										<el-table-column prop="MemberProductCode" label="地址" width="400">
											<template slot-scope="scope">
												<el-col :span="23">
													<Distpicker :placeholders="scope.row"></Distpicker>
												</el-col>
											</template>
										</el-table-column>
										<el-table-column label="详细地址" width="300">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-form-item :prop="'AddressInfoList.' + scope.$index + '.AddressDetail'" :rules="baseCheckRule.AddressDetail">
														<el-input v-model="scope.row.AddressDetail" size="mini" placeholder="详细地址" />
													</el-form-item>
												</el-col>
											</template>
										</el-table-column>
										<el-table-column label="经纬度" width="260">
											<template slot-scope="scope">
												<el-col :span="10">
													<el-input v-model="scope.row.Longitude" size="mini" placeholder="经度" />
												</el-col>
												<el-col :span="10">
													<el-input v-model="scope.row.Latitude" size="mini" placeholder="纬度" />
												</el-col>
												<el-col :span="4">
													<el-button @click="locate(scope.row)">定位</el-button>
												</el-col>
											</template>
										</el-table-column>
										<el-table-column prop="ContactName" label="联系人">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-form-item :prop="'AddressInfoList.' + scope.$index + '.ContactName'" :rules="baseCheckRule.ContactName">
														<el-input v-model="scope.row.ContactName" size="mini" placeholder="联系人" />
													</el-form-item>
												</el-col>
											</template>
										</el-table-column>
										<el-table-column prop="ContactPhone" label="联系电话">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-form-item :prop="'AddressInfoList.' + scope.$index + '.ContactName'" :rules="baseCheckRule.ContactPhone">
														<el-input v-model="scope.row.ContactPhone" size="mini" placeholder="联系电话" />
													</el-form-item>
												</el-col>
											</template>
										</el-table-column>
										<el-table-column fixed="right" label="操作" width="120">
											<template slot-scope="scope">
												<el-button size="small" type="text" @click="dataSource.AddressInfoList.splice(scope.$index, 1)">删除</el-button>
											</template>
										</el-table-column>
									</el-table>
								</div>
							</div>
						</div> 
					</el-tab-pane>
				</el-tabs>
				<el-tabs class="done" type="border-card" v-model="activeName3">
					<el-tab-pane label="合同信息" name="tab1">
						<ContractList ref="refcontractlist" :customerId="dataSource.CustomerID">
						</ContractList>
					</el-tab-pane>
				</el-tabs>
			</el-form>
		</div>
				

		<!--重置密码-->
		<DialogEx :options="CustomerOptions" title="重置密码" @onOk="resetPwd_onOk" style="width:70%;margin-left:15%;">
			<ResetPwd ref="resetCustomerPwd" :CustomerID="CustomerID" :CustomerName="CustomerName"></ResetPwd>
			<template slot="ButtonGroupLeft">
				<el-button type="primary" @click="resetPwdForm">清空</el-button>
			</template>
		</DialogEx>
		<el-dialog title="承运商产品" :visible.sync="freightPayVisible" width="45%" :before-close="handleClose"
			:show-close="false">
			<el-table :data="freightPayData.productPayList" border highlight-current-row>
				<el-table-column prop="MemberProductName" label="承运商产品" min-width="120px">
				</el-table-column>
				<el-table-column label="是否默认产品" min-width="120px">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.IsDefaultMemberProduct"
							@change="defaultMemberProduct(scope.$index, scope.row)" active-color="#13ce66"
							inactive-color="#ff4949"></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="默认运费支付方式" min-width="120px">
					<template slot-scope="scope">
						<el-select v-model="scope.row.freightPayType" placeholder="请选择">
							<el-option v-for="item in freightPayOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column prop="IsAckOrder" label="是否回单" min-width="100px">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.IsAckOrder" active-color="#13ce66" inactive-color="#ff4949">
						</el-switch>
					</template>
				</el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancelFreightPayType">取 消</el-button>
				<el-button type="primary" @click="saveFreightPayType"
					:disabled="freightPayData.productPayList.length==0">保 存</el-button>
			</div>
		</el-dialog>
		<el-dialog title="设置电子面单账号" :visible.sync="faceBillVisible" width="65%" :before-close="handleClose"
			:show-close="false">
			<el-button type="primary" @click="addFaceBill">添加</el-button>
			<el-table :data="dataSource.CustomerFaceBillList" border highlight-current-row>
				<el-table-column label="承运商产品" min-width="120px">
					<template slot-scope="scope">
						<el-select v-model="scope.row.MemberProductCodeArr" placeholder="请选择" multiple collapse-tags>
							<el-option v-for="item in faceBillProductList" :key="item.MemberProductCode"
								:label="item.MemberProductName" :value="item.MemberProductCode">
							</el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column label="仓库" min-width="120px">
					<template slot-scope="scope">
						<el-select v-model="scope.row.WarehouseNoArr" placeholder="请选择" multiple collapse-tags>
							<el-option v-for="item in dataSource.CustomerWarehouseList" :key="item.WarehouseNo"
								:label="item.WarehouseName" :value="item.WarehouseNo">
							</el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column label="店铺" min-width="120px">
					<template slot-scope="scope">
						<el-select v-model="scope.row.ShopNoArr" placeholder="请选择" multiple collapse-tags>
							<el-option v-for="item in dataSource.CustomerShopList" :key="item.ShopNo"
								:label="item.ShopName" :value="item.ShopNo">
							</el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column prop="ExCustomerName" label="客户账户" min-width="80px">
					<template slot-scope="scope">
						<el-input v-model="scope.row.ExCustomerName" :maxlength="30"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="ExCustomerPwd" label="电子面单密码" min-width="80px">
					<template slot-scope="scope">
						<el-input v-model="scope.row.ExCustomerPwd" :maxlength="30"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="ExMonthCode" label="月结卡号" min-width="80px">
					<template slot-scope="scope">
						<el-input v-model="scope.row.ExMonthCode" :maxlength="30"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="ExSendSite" label="收货网点标识" min-width="80px">
					<template slot-scope="scope">
						<el-input v-model="scope.row.ExSendSite" :maxlength="30"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="BankCardNo" label="代收货款账号" min-width="80px">
					<template slot-scope="scope">
						<el-input v-model="scope.row.BankCardNo" :maxlength="30"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="50px">
					<template slot-scope="scope">
						<el-button type="text" @click="delFaceBill(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancelFaceBill">取 消</el-button>
				<el-button type="primary" @click="saveFaceBill"
					:disabled="dataSource.CustomerFaceBillList==undefined||dataSource.CustomerFaceBillList==null||  dataSource.CustomerFaceBillList.length==0">
					保存
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			var checkCustomerName = function(rule, value, callback) {
				if (!value) return callback(new Error('客户名称不能为空'));
				callback();
			};
			var checkCustomerAddress = function(rule, value, callback) {
				if (!this.isDisabled) {
					if (!value) return callback(new Error('所在地址不能为空'));
				}
				callback();
			};
			var checkCustomerContactName = function(rule, value, callback) {
				if (!this.isDisabled) {
					if (!value) return callback(new Error('联系人不能为空'));
				}
				callback();
			};
			var checkCustomerContactPhone = (rule, value, callback) => {
				if (!this.isDisabled) {
					if (!value)
						return callback(new Error('联系电话不能为空'));
					else if (!this.Utils.isMobile(value))
						return callback(new Error('联系电话格式不正确.'));
					else
						return callback();
				}
				callback();
			};
			//var checkDomainName = function (rule, value, callback) {
			//    if (!value) return callback(new Error('租户域名不能为空'));
			//    callback();
			//};
			return {
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() < Date.now();
					}
				},
				isDisabled: false,
				activeName: 'tabCustomerInfo',
				activeName1: 'tabproductinfo',
				activeName2: "tabproductinfo",
				activeName3: "tab1",
				baseCheckRule: {
					CustomerName: [{
						validator: checkCustomerName,
						trigger: 'blur'
					}],
					CustomerAddress: [{
						validator: checkCustomerAddress,
						trigger: 'blur'
					}],
					CategoryCode: [{
						required: true,
						message: '请选择行业类型',
						trigger: 'blur'
					}],
					AddressDetail: [{
						required: true,
						message: '请输入地址',
						trigger: 'blur'
					}],
					ContactName: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}],
					ContactPhone: [{
						required: true,
						message: '请输入联系电话',
						trigger: 'blur'
					}],
				},
				accountInfoCheckRule: {
					//DomainName: [{ validator: checkDomainName, trigger: 'blur' }],
					UserPassword: [{
						validator: this.checkUserPassword,
						trigger: 'blur'
					}]
				},
				CustomerName: '',
				CustomerID: '',
				CustomerOptions: {
					visible: false
				},
				hyptions: [],
				dicOptions: [],
				warehouseSelection: [],
				memberSelection: [],
				memberCurrentRow: {},
				//电子面单
				faceBillVisible: false,
				faceBillProductList: [],
				//运费支付方式
				freightPayVisible: false,
				freightPayData: {
					MemberCode: '', //承运商编码
					productPayList: []
				},
				freightPayOptions: [{
					value: 100,
					label: '月结'
				}, {
					value: 200,
					label: '到付'
				}, {
					value: 300,
					label: '第三方付'
				}],
			}
		},
		props: {
			config: {
				isDetailDisplay: false
			},
			dataSource: {}
		},
		mounted() {
			this.Event.$on("clearEditCustomerInfoForm", () => this.resetForm());
		},
		watch: {
			dataSource: {
				handler(curVal, oldVal) {
					this.isDisabled = this.Utils.emptyGuid != this.dataSource.CustomerID;
					if (curVal.CustomerID != oldVal.CustomerID) {
						this.activeName = 'tabCustomerInfo';
					}
				},
				deep: true
			},
		},
		created: function() {
			this.toggleSelection();
			this.Event.$on("clearForm", this.resetForm);
		},
		computed: {},
		methods: {
			addCustomerInfo() {
				this.Event.$emit("onAddCustomerInfo");
			},
			back() {
				this.$parent.checkSaveStatus();
				this.dataSource.CustomerID = null;
			},
			resetForm: function(memberId) {
				this.dataSource.CustomerMemberList = [];
				//    !this.isPassValidate && this.$refs['_customerInfoForm'].resetFields();//清空表单
			},
			tabClick(tab, event) {
				if (tab.name == "tabWarehouse") {
					if (this.dataSource.WarehouseNos) {
						var codeArr = this.dataSource.WarehouseNos.split(',');
						if (this.dataSource.CustomerWarehouseList) {
							this.dataSource.CustomerWarehouseList.forEach(w => {
								codeArr.forEach(code => {
									if (w.WarehouseNo == code) {
										this.$refs.customerWarehouseTable.toggleRowSelection(w, true);
									}
								});
							});
						}
					}
				}
				if (tab.name == "tabMember") {
					if (this.dataSource.MemberCodes) {
						var codeArr = this.dataSource.MemberCodes.split(',');
						if (this.dataSource.CustomerMemberList) {
							this.dataSource.CustomerMemberList.forEach(c => {
								codeArr.forEach(code => {
									if (c.MemberCode == code) {
										this.$refs.customerMemberTable.toggleRowSelection(c, true);
									}
								});
							});
						}
					}
				}
			},
			toggleSelection() {
				if (this.dataSource.CustomerWarehouseList != null && this.dataSource.CustomerWarehouseList.length > 0) {
					this.dataSource.CustomerWarehouseList.forEach(row => {
						this.dataSource.WarehouseNos.forEach(code => {
							if (row.WarehouseNo == code) {
								this.$refs.customerWarehouseTable.toggleRowSelection(row);
							}
						});
					});
				}
				if (this.dataSource.CustomerMemberList != null && this.dataSource.CustomerMemberList.length > 0) {
					this.dataSource.CustomerMemberList.forEach(row => {
						this.dataSource.MemberCodes.forEach(code => {
							if (row.MemberCode == code) {
								this.$refs.customerMemberTable.toggleRowSelection(row);
							}
						});
					});
				}
			},
			warehouseSelectionChange(rows) {
				this.warehouseSelection = rows;
			},
			memberSelectionChange(rows) {
				this.memberSelection = rows;
			},
			defaultRKWarehouse(index, row) {
				this.dataSource.CustomerWarehouseList.forEach(warehouse => {
					if (warehouse.WarehouseNo == row.WarehouseNo) {
						this.dataSource.DefaultRKWarehouseCode = row.WarehouseNo;
						return;
					}
					warehouse.DefaultRKWarehouse = false;
				});
			},
			defaultCKWarehouse(index, row) {
				this.dataSource.CustomerWarehouseList.forEach(warehouse => {
					if (warehouse.WarehouseNo == row.WarehouseNo) {
						this.dataSource.DefaultCKWarehouseCode = row.WarehouseNo;
						return;
					}
					warehouse.DefaultCKWarehouse = false;
				});
			},
			defaultRKMember(index, row) {
				this.dataSource.CustomerMemberList.forEach(member => {
					if (member.MemberCode == row.MemberCode) {
						this.dataSource.DefaultRKMemberCode = row.MemberCode;
						return;
					}
					member.DefaultRKMember = false;
				});
			},
			defaultCKMember(index, row) {
				this.dataSource.CustomerMemberList.forEach(member => {
					if (member.MemberCode == row.MemberCode) {
						this.dataSource.DefaultCKMemberCode = row.MemberCode;
						return;
					}
					member.DefaultCKMember = false;
				});
			},
			faceBillDialog(row) {
				this.memberCurrentRow = row;
				if (this.dataSource.CustomerID != this.Utils.emptyGuid) {
					this.$ajax.send("omsapi/customer/getfacebillinfobycode", "get", {
						customerCode: row.CustomerCode,
						memberCode: row.MemberCode
					}, (data) => {
						this.dataSource.CustomerFaceBillList = data.Result;
						if (this.dataSource.CustomerFaceBillList) {
							this.dataSource.CustomerFaceBillList.forEach(f => {
								f.MemberProductCodeArr = !f.MemberProductCodes ? [] : f.MemberProductCodes
									.split(',');
								f.WarehouseNoArr = !f.WarehouseNos ? [] : f.WarehouseNos.split(',');
								f.ShopNoArr = !f.ShopNos ? [] : f.ShopNos.split(',');
							});
						}
					});
				}
				if (row.CarrierProductList != null && row.CarrierProductList.length > 0) {
					row.CarrierProductList.forEach(r => {
						this.faceBillProductList.push({
							MemberProductCode: r.MemberProductCode,
							MemberProductName: r.MemberProductName
						});
					});
				}
				this.faceBillVisible = true;
			},
			addFaceBill() {
				var newRow = {
					CustomerCode: this.dataSource.CustomerCode,
					CustomerName: this.dataSource.CustomerName,
					MemberCode: this.memberCurrentRow.MemberCode,
					MemberName: this.memberCurrentRow.MemberName,
					MemberProductCodeArr: '',
					WarehouseNoArr: '',
					ShopNoArr: '',
					ExCustomerName: '',
					ExCustomerPwd: '',
					ExMonthCode: '',
					ExSendSite: '',
					BankCardNo: ''
				};
				this.dataSource.CustomerFaceBillList.push(newRow);
			},
			delFaceBill(row) {
				this.$confirm('确定要删除吗？').then(_ => {
					if (row.CustomerSettingID && row.CustomerSettingID != this.Utils.emptyGuid) {
						this.$ajax.send("omsapi/customer/delfacebill", "get", {
							id: row.CustomerSettingID
						}, (data) => {
							this.Utils.messageBox("删除成功.", "success");
						});
					}
					this.dataSource.CustomerFaceBillList.remove(row);
				}).catch(_ => {});
			},
			cancelFaceBill() {
				this.faceBillProductList = [];
				this.faceBillVisible = false;
			},
			saveFaceBill() {
				if (this.dataSource.CustomerFaceBillList == undefined || this.dataSource.CustomerFaceBillList == null ||
					this.dataSource.CustomerFaceBillList.length == 0) {
					return;
				}
				var isOk = true;
				this.dataSource.CustomerFaceBillList.forEach(f => {
					if (!f.MemberProductCodeArr || f.MemberProductCodeArr == null || f.MemberProductCodeArr ==
						'') {
						isOk = false;
					}
					if (!f.WarehouseNoArr || f.WarehouseNoArr == null || f.WarehouseNoArr == '') {
						isOk = false;
					}
					f.MemberProductCodes = f.MemberProductCodeArr.join();
					f.WarehouseNos = f.WarehouseNoArr.join();
					f.ShopNos = f.ShopNoArr.join();
				});
				if (!isOk) {
					this.Utils.messageBox("承运商产品/仓库不能为空.", "error");
					return;
				}
				this.$ajax.send("omsapi/customer/savefacebill", "post", this.dataSource.CustomerFaceBillList, (data) => {
					this.Utils.messageBox("保存成功.", "success");
				});
			},
			//默认运费支付方式操作
			defaultPayTypeDialog(row) {
				this.freightPayData.MemberCode = row.MemberCode;
				this.freightPayData.productPayList = [];
				if (row.CarrierProductList != null && row.CarrierProductList.length > 0) {
					row.CarrierProductList.forEach(r => {
						this.freightPayData.productPayList.push({
							MemberProductCode: r.MemberProductCode,
							MemberProductName: r.MemberProductName,
							freightPayType: null,
							IsDefaultMemberProduct: false
						});
						this.freightPayData.productPayList.forEach(p => {
							if (r.MemberProductCode == p.MemberProductCode) {
								p.MemberProductName = r.MemberProductName;
							}
						});
					});
				}
				this.$ajax.send("omsapi/customer/getfreightpayinfobycode", "get", {
					customerCode: row.CustomerCode,
					memberCode: row.MemberCode
				}, (data) => {
					if (data.Result != null && data.Result.length > 0) {
						data.Result.forEach(f => {
							this.freightPayData.productPayList.forEach(p => {
								if (f.MemberProductCode == p.MemberProductCode) {
									p.freightPayType = f.FreightPayType;
									p.IsDefaultMemberProduct = f.IsDefaultMemberProduct;
									p.IsAckOrder = f.IsAckOrder;
								}
							});
							if (!f.MemberProductCode) {
								this.freightPayData.MemberCode = f.MemberCode;
							}
						});
					}
				});
				this.freightPayVisible = true;
			},
			defaultMemberProduct(index, row) {
				this.freightPayData.productPayList.forEach(pay => {
					if (pay.MemberProductCode == row.MemberProductCode) {
						return;
					}
					pay.IsDefaultMemberProduct = false;
				});
			},
			cancelFreightPayType() {
				this.freightPayData.productPayList = [];
				this.freightPayVisible = false;
			},
			saveFreightPayType() {
				if (this.freightPayData == null) {
					return;
				}
				var list = [];
				if (this.freightPayData.productPayList != null && this.freightPayData.productPayList.length > 0) {
					this.freightPayData.productPayList.forEach(p => {
						var row = {
							CustomerCode: this.dataSource.CustomerCode,
							MemberCode: this.freightPayData.MemberCode,
							FreightPayType: p.freightPayType,
							MemberProductCode: p.MemberProductCode,
							IsDefaultMemberProduct: p.IsDefaultMemberProduct,
							IsAckOrder: p.IsAckOrder
						};
						list.push(row);
					});
				}
				this.$ajax.send("omsapi/customer/savefreightpay", "post", list, (data) => {
					this.Utils.messageBox("保存成功", "success");
				});
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						this.freightPayData.productPayList = [];
						this.faceBillProductList = [];
						done();
					}).catch(_ => {});
			},
			saveCustomerInfo() {
				var _this = this;
				if (this.dataSource.IsOpenShipper && (!this.isDisabled || (this.isDisabled && !this.dataSource
						.IsExistUser))) {
					if (this.dataSource.DomainName == null || this.dataSource.DomainName == '') {
						this.Utils.messageBox("请输入域名.", "error");
						return;
					}
					if (this.Utils.isChinese(this.dataSource.DomainName)) {
						this.Utils.messageBox("域名不能输入汉字.", "error");
						return;
					}
					if (this.dataSource.UserPassword !== this.dataSource.UserPasswordConfirm) {
						this.Utils.messageBox("两次输入的账号密码不一致.", "error");
						return;
					}
				}
				//if (this.Utils.isChinese(this.dataSource.DomainName)) {
				//    this.Utils.messageBox("域名不能输入汉字.", "error");
				//    return;
				//}
				var isPassValidate = false;
				var isPassAccountValidate = true;
				_this.$refs["_customerInfoForm"].validate((valid) => {
					isPassValidate = valid;
				});
				/* 	_this.$refs["_accountInfoForm"].validate((valid) => {

isPassAccountValidate = valid;
            	}); */
				if (isPassValidate && isPassAccountValidate) {
					if (_this.warehouseSelection.length > 0) {
						_this.dataSource.WarehouseNos = this.Utils.selectionsToArr(_this.warehouseSelection,
							"WarehouseNo");
						_this.dataSource.WarehouseNos = _this.dataSource.WarehouseNos.join();
					}
					if (_this.memberSelection.length > 0) {
						_this.dataSource.MemberCodes = this.Utils.selectionsToArr(_this.memberSelection, "MemberCode");
						_this.dataSource.MemberCodes = _this.dataSource.MemberCodes.join();
					}
					var routeName = _this.dataSource.CustomerID === null ? "add" : "savecustomer";
					this.$ajax.send("omsapi/customerinfo/" + routeName, "post", _this.dataSource, (data) => {
						_this.dataSource.CustomerID = data.Result;
						_this.$parent.syncDataSource();
						_this.Event.$emit("reloadCustomerInfoPageList", data.Result);
						_this.Utils.messageBox("保存成功.", "success");
						if (routeName == "savecustomer" && this.dataSource.IsOpenShipper) {
							this.dataSource.IsExistUser = true;
						}
					});
				} else {
					return false;
				}
			},
			checkUserPassword(rule, value, callback) {
				if (this.dataSource.IsOpenShipper && (!this.isDisabled || (this.isDisabled && !this.dataSource
						.IsExistUser))) {
					if (value === '' || !value) return callback(new Error('密码不能为空'))
					else {
						// if (value.length < 8) {
						//     return callback(new Error('密码至少八位或者八位以上'))
						// }
						// var patrn = /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)(?![a-zA-z\d]+$)(?![a-zA-z!@#$%^&*]+$)(?![\d!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/;
						// if (!patrn.exec(value)) return callback(new Error('密码必须包含数字，字母，特殊字符'))
					}
				}
				callback();
			},
			showReset: function() {
				this.CustomerOptions.visible = true;
				this.CustomerName = this.dataSource.CustomerName;
				this.CustomerID = this.dataSource.CustomerID;
				this.$refs.resetCustomerPwd.resetForm();
			},
			resetPwd_onOk(options) {
				this.$refs.resetCustomerPwd.resetPwd(options);
			},
			resetPwdForm() {
				this.$refs.resetCustomerPwd.resetForm();
			},
			addContactInfo: function() {
				if (!this.dataSource.ContactInfoList) {
					this.dataSource.ContactInfoList = [];
				}
				var data = {
					ContactName: null,
					ContactPhone: null,
					Email: null
				};
				this.dataSource.ContactInfoList.push(data);
			},
			removeContactInfo: function(row) {
				this.dataSource.ContactInfoList.remove(row);
			},
			addAddressInfo: function() {
				if (!this.dataSource.AddressInfoList) {
					this.dataSource.AddressInfoList = [];
				}

				var list = [];
				list.push({
					Key: 0,
					Value: "装货地"
				});
				list.push({
					Key: 5,
					Value: "卸货地"

				});
				list.push({
					Key: 10,
					Value: "装卸货地"
				});
				var data = {
					AddressType: null,
					AddressTypeList: list,
					ProvinceName: null,
					CityName: null,
					CountyName: null,
					ProvinceID: null,
					CityID: null,
					CountyID: null,
					AddressDetail: null,
					Longitude: null,
					Latitude: null,
					ContactName: null,
					ContactPhone: null,
				};
				this.dataSource.AddressInfoList.push(data);
			},
			removeAddressInfo: function(row) {
				this.dataSource.AddressInfoList.remove(row);
			},
			locate(row){
				this.Utils.setLocation(row);
			}
		},
		components: {
			"ResetPwd": resolve => {
				require(['./resetpwd.vue'], resolve)
			},
			"ContactInfoSelector": resolve => {
				require(['../../../../business/member/shipprovider/components/contactselector.vue'], resolve)
			},
			"AddressInfoSelector": resolve => {
				require(['../../../../business/member/shipprovider/components/addressselector.vue'], resolve)
			},
			"ContractList": resolve => {
				require(['@/components/enterprise/selector/customercontractlist.vue'], resolve)
			},
		}
	}
</script>

<style scoped>
	.h-handle-es {
		margin-bottom: 10px;
	}

	.h-handle-es .el-button {
		border-color: #e5740f;
		background-color: #e5740f;
		color: #fff;
	}

	.form-box {
		padding: 20px;
		/*border:solid 1px #DDDDDD;*/
		border-radius: 5px;
		margin-top: 10px;
	}

	.form-box:after {
		content: ".";
		display: block;
		height: 0;
		visibility: hidden;
		clear: both;
	}
</style>